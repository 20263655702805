import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { API } from "../../constants/API";

interface checkObj {
  status?: number;
  message?: string;
}

interface onBoardingState {
  err?: string;
  status?: string;
  onboarding?: checkObj;
  setPass?: checkObj;
}

const initialState: onBoardingState = {
  err: "",
  status: "",
  setPass: {
    status: 0,
    message: "",
  },
  onboarding: {
    status: 0,
    message: "",
  },
};

export const setPassword = createAsyncThunk(
  "setPassword",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.SETPASSWORD, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const checkOnboarding = createAsyncThunk(
  "checkOnboarding",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.CHECKONBOARDING, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const onBoardingSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    emptyOnboardingObj: (state) => {
      state.status = "";
      state.err = "";
      if (state.onboarding) {
        state.onboarding.status = 0;
        state.onboarding.message = "";
      }
      if (state.setPass) {
        state.setPass.status = 0;
        state.setPass.message = "";
      }
    },
  },
  extraReducers: (builder) => {
    // on boarding set Password
    builder
      .addCase(setPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setPassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        if (state.setPass) {
          state.setPass.status = action.payload.status;
          state.setPass.message = action.payload.data.message;
        }
      })
      .addCase(setPassword.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (state.setPass) {
          state.setPass.status = action.payload.status;
          state.setPass.message = action.payload.data.message;
        }
        state.err = action.payload;
      });

    builder
      .addCase(checkOnboarding.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        checkOnboarding.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          if (state.onboarding) {
            state.onboarding.status = action.payload.status;
            state.onboarding.message = action.payload.data.message;
          }
        }
      )
      .addCase(
        checkOnboarding.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          if (state.onboarding) {
            state.onboarding.status = action.payload.status;
            state.onboarding.message = action.payload.data.message;
          }
          state.err = action.payload;
        }
      );
  },
});

export const { emptyOnboardingObj } = onBoardingSlice.actions;

export const onBoardingData = (state: RootState) => state.onBoardingReducer;

export default onBoardingSlice.reducer;
