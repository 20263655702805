import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/API";
import { RootState } from "../store";
import { safeMultipleDatesFormat } from "react-datepicker/dist/date_utils";

export interface DataItem {
  _id?: string;
  account_name?: string;
  account_number?: string | number;
  claim_number?: string;
  isbn?: string;
  title?: string;
  description?: string;
  dollar_amount?: string;
  approved_by?: string;
  time_warner_ar?: string;
  code_as?: string;
  credit_number?: string;
  credit_date?: string;
  promotion_details?: string;
}

interface DataState {
  data: DataItem[];
  totalPages?: number;
  status: string;
  statusCode: number;
  error: string | null;
  page: number;
  limit: number;
  account_name: string;
  account_number: string;
  message: string;
  totalDocuments: number;
  isLoading: boolean;
}
const initialState: DataState = {
  data: [],
  account_name: "",
  account_number: "",
  status: "",
  error: null,
  statusCode: 0,
  page: 1,
  limit: 10,
  message: "",
  totalPages: 0,
  totalDocuments: 0,
  isLoading: false,
};

interface accountDetails {
  account_id: string | number;
  page: number;
  limit: number;
  searchTerm?: string;
}

export const postAmountDetail = createAsyncThunk(
  "postAmountDetail",
  async (data: accountDetails, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.POSTACCOUNTDETAILS, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

// export const postAmountDetail = createAsyncThunk<AmountDetailState, { rejectValue: string }>(
//     "postAmountDetail",
//     async (data, { rejectWithValue }) => {
//       try {
//         const response = await axios.post(API.POSTACCOUNTDETAILS, data);
//         console.log(response.data)
//         return response.data;
//       } catch (err: any) {
//         console.log(err,"error")
//         return rejectWithValue(err.response?.data || 'An error occurred');
//       }
//     }
// );

const AmountDataSlice = createSlice({
  name: "amountData",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      console.log(action.payload, "actualData");
    },
    adjustPageAndLimit: (state) => {
      state.page = state.page + 1;
    },
    emptyAccountDetails: (state) => {
      state.data = initialState.data;
      state.page = initialState.page;
      state.limit = initialState.limit;
      state.account_name = initialState.account_name;
      state.account_number = initialState.account_number;
    },
    emptyStatus: (state) => {
      state.statusCode = 0;
      state.message = initialState.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAmountDetail.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(postAmountDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "succeeded";
        state.data = action.payload.data.data;
        if (!state.account_name?.length) {
          state.account_name = action.payload.data.accountNumber;
          state.account_number = action.payload.data.accountName;
        }
        state.statusCode = action.payload.status;
        state.totalPages = action.payload.data.pagination.totalPages;
        state.totalDocuments = action.payload.data.pagination.totalDocuments;
      })
      .addCase(
        postAmountDetail.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.isLoading = false;
          if (action.payload.status === 404) {
            state.data = [];
            state.totalPages = 0;
            state.totalDocuments = 0;
            state.account_name = action.payload.data.accountNumber;
            state.account_number = action.payload.data.accountName;
          }
          state.error = action.payload.data?.message;
          state.statusCode = action.payload.status;
          state.message = action.payload.data.message
            ? action.payload.data.message
            : (action.payload.data.msg && action.payload.data.msg) ||
              "error occured";
        }
      );
  },
});

export const selectData = (state: RootState) => state.amountReducer.data;

export const { emptyStatus, setData, adjustPageAndLimit, emptyAccountDetails } =
  AmountDataSlice.actions;
// export const selectFirstDataItem = (state: RootState) => state.amountReducer.data[0];
// export const AmountData = (state: RootState) => state.amountReducer.AmountDetailData
export default AmountDataSlice.reducer;
