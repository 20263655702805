import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { API } from "../../constants/API";

interface notificationObj {
  _id: string;
  notification_type: string;
  notification_message: string;
  isread_status: boolean;
  is_deleted: boolean;
  read_at: Date;
}
interface notificationState {
  message?: string;
  err?: string;
  status?: string;
  statusCode?: number;
  notifications: notificationObj[];
}

const initialState: notificationState = {
  err: "",
  status: "",
  message: "",
  statusCode: 0,
  notifications: [],
};

export const fetchAllUnreadNotifications = createAsyncThunk(
  "notifications/fetchAllUnreadNotifications",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API.FETCHLOGINUSERNOTIFICATION}`);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const updateNotificationsStatus = createAsyncThunk(
  "notifications/updateNotificationsStatus",
  async (ids: string[], { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API.UPDATENOTIFICATIONSTATUS}`, {
        ids: ids,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const userSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateNotificationReadStatus: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => !action.payload.includes(notification._id)
      );
    },
    // updateNotificationReadStatus:(state,action) => {
    //   state.notifications = state.notifications.map(notification => {
    //     if (!action.payload.includes(notification._id)) {
    //       return notification;
    //     }
    //     return notification
    // })
  },
  extraReducers: (builder) => {
    // login user cases

    builder
      .addCase(fetchAllUnreadNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchAllUnreadNotifications.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          if (action.payload.status == 200)
            state.notifications = action.payload.data;
          if (action.payload.data.message)
            state.message = action.payload.data.message;
          state.statusCode = action.payload.status;
        }
      )
      .addCase(
        fetchAllUnreadNotifications.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.statusCode = action.payload.status;
          if (action.payload.data.message)
            state.message = action.payload.data.message;
        }
      );

    builder
      .addCase(updateNotificationsStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updateNotificationsStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.statusCode = action.payload.status;
        }
      )
      .addCase(
        updateNotificationsStatus.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.statusCode = action.payload.status;
          if (action.payload.data.message)
            state.message = action.payload.data.message;
        }
      );
  },
});

export const { updateNotificationReadStatus } = userSlice.actions;

export const userProfile = (state: RootState) => state.profileReducer;

export default userSlice.reducer;
