import { LogLevel } from "@azure/msal-browser";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const TENANT_ID = process.env.REACT_APP_TENANT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const APPNAME = process.env.REACT_APP_APPNAME;

if (!CLIENT_ID || !TENANT_ID || !REDIRECT_URI) {
    throw new Error("Missing necessary environment variables for MSAL configuration");
  }

export const API_SCOPE = "api://" + CLIENT_ID + APPNAME;

export const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        redirectUri: REDIRECT_URI,
        postLogoutRedirectUri: REDIRECT_URI,
        // scope: API_SCOPE,
        // domain: "YourDomain",
    },
    cache: {// Optional
        cacheLocation: 'localStorage',  // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false,  // Set this to "true" if you are having issues on IE11 or Edge
    },
    system:{
        loggerOptions:{
            loggerCallback:(level:any,message:any,containsPii:any) => {
                if(containsPii){
                    console.log('contains pii')
                    return
                }
                switch(level){
                    case LogLevel.Error:
                        console.error(message)
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                    default:
                        return;
                    
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: [API_SCOPE]
};

export const userDataLoginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};