import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/API";
import { RootState } from "../store";

interface CoopData {
  _id: string;
  Budget: number;
  incurred: number;
  Remaining: number;
  Account_name: string;
  Account_number: string;
  Account_id: string;
}

interface BudgetState {
  CoopData: CoopData[];
  status: string;
  statusCode: number;
  message: string;
  error: string | null;
  totalBudget: number;
  totalIncurred: number;
  totalRemaining: number;
  isLoading: boolean;
}

const initialState: BudgetState = {
  CoopData: [],
  status: "",
  statusCode: 0,
  message: "",
  error: null,
  totalBudget: 0,
  totalIncurred: 0,
  totalRemaining: 0,
  isLoading: false,
};
export const getCoopSummary = createAsyncThunk(
  "getCoopSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.GETCOOPSUMMARY);
      console.log(response, "response");
      return response;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue("error occurred");
    }
  }
);

const CoopSummarySlice = createSlice({
  name: "CoopSummary",
  initialState,
  reducers: {
    emptyCoopSummary:(state)=>{
      state.CoopData=initialState.CoopData;
      state.error=initialState.error
      state.status=initialState.status
      state.statusCode=initialState.statusCode
      state.isLoading=initialState.isLoading
      state.message=initialState.message
      state.totalBudget=initialState.totalBudget
      state.totalIncurred=initialState.totalIncurred
      state.totalRemaining=initialState.totalRemaining
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoopSummary.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getCoopSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "succeeded";
        (state.CoopData = action.payload.data.data),
          (state.totalBudget = action.payload.data.summary.totalBudget);
        state.totalIncurred = action.payload.data.summary.totalIncurred;
        state.totalRemaining = action.payload.data.summary.totalRemaining;

        // state.status=action.payload.status
        console.log(action.payload.data.data, "action");
      })
      .addCase(getCoopSummary.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.isLoading = false;
      });
  },
});
export const {emptyCoopSummary}=CoopSummarySlice.actions

export const CoopData = (state: RootState) => state.CoopSummaryReducer.CoopData;
export const totalBudget = (state: RootState) =>
  state.CoopSummaryReducer.totalBudget;
export const totalIncurred = (state: RootState) =>
  state.CoopSummaryReducer.totalIncurred;
export const totalRemaining = (state: RootState) =>
  state.CoopSummaryReducer.totalRemaining;
export default CoopSummarySlice.reducer;
