import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { API } from "../../constants/API";

export interface Account {
  id?: number;
  Account_name?: string;
  Account_number?: number;
  Budget?: number;
  Remaining?: number;
  incurred?: number;
}
export interface dashboard {
  totalBudget?: number;
  totalRemaining?: number;
  totalIncurred?: number;
}

export interface DashboardState {
  accounts: Account[];
  dashboard?: dashboard;
  loading: boolean | undefined;
  error: string | null;
  status?: string;
  statusCode?: number;
  message?: string;
  currentPage: number;
  limit: number;
  unchangeTotalSummary?: dashboard;
  totalDocuments?: number;
  totalPages?: number;
}

const initialState: DashboardState = {
  accounts: [],
  loading: false,
  error: null,
  status: "",
  statusCode: 0,
  totalDocuments: 0,
  totalPages: 0,
  dashboard: {},
  currentPage: 1,
  limit: 5,
  unchangeTotalSummary: {},
};

interface FetchDashboardParams {
  currentPage: number;
  limit: number;
  searchTerm?: string;
}
export const fetchDashBoard = createAsyncThunk(
  "fetchAccount",
  async (data: FetchDashboardParams, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.FETCHDASHBOARD, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);
export const DashboardSlice = createSlice({
  name: "dashBoard",
  initialState,
  reducers: {
    updateDashboard: (
      state,
      action: PayloadAction<{
        Budget?: number;
        Remaining?: number;
        incurred?: number;
      }>
    ) => {
      const { Budget, Remaining, incurred } = action.payload;

      if (state.dashboard?.totalBudget) state.dashboard.totalBudget = Budget;
      if (state.dashboard?.totalRemaining)
        state.dashboard.totalRemaining = Remaining;
      if (state.dashboard?.totalIncurred)
        state.dashboard.totalIncurred = incurred;
    },
    adjustPageAndLimit: (state) => {
      state.currentPage += 1;
    },
    setUnchangedTotalSummary: (state) => {
      if (state.unchangeTotalSummary) {
        state.dashboard = { ...state.unchangeTotalSummary };
      }
    },
    decreasePageAndlimit: (state) => {
      if (state.currentPage && state.limit) {
        state.limit = state.limit / state.currentPage;
        state.currentPage = state.currentPage - 1;
      }
    },
    emptyAccount: (state) => {
      if (state.accounts) {
        state.accounts = [];
      }
      state.statusCode = initialState.statusCode;
      state.currentPage = initialState.currentPage;
      state.limit = initialState.limit;
      state.status = initialState.status;
      state.message = initialState.message;
    },
    emptyStatus: (state) => {
      state.statusCode = initialState.statusCode;
      state.message = initialState.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashBoard.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(
        fetchDashBoard.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.accounts = action.payload.data.data;
          state.dashboard = action.payload.data.summary;
          state.unchangeTotalSummary = action.payload.data.summary;
          state.totalDocuments = action.payload.data.totalDocuments;
          state.totalPages = action.payload.data.totalPages;
          state.statusCode = action.payload.status;
          state.loading = false;
          state.message = "";
        }
      )
      .addCase(fetchDashBoard.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload?.status === 404) {
          state.accounts = [];
          state.totalDocuments = 0;
          state.totalPages = 0;
        }
        state.loading = false;
        state.statusCode = action.payload?.status;
        state.message = action.payload.data?.message
          ? action.payload.data.message
          : (action.payload.data.msg && action.payload.data.msg) ||
            "An error occurred";
      });
  },
});
export const Accounts = (state: RootState) => state.dashBoardReducer.accounts;
export const dashboard = (state: RootState) => state.dashBoardReducer.dashboard;
export const CurrentPage = (state: RootState) =>
  state.dashBoardReducer.currentPage;
export const Limit = (state: RootState) => state.dashBoardReducer.limit;
export const dashboardStatus = (state: RootState) =>
  state.dashBoardReducer.statusCode;
export const unchangeTotalSummary = (state: RootState) =>
  state.dashBoardReducer.unchangeTotalSummary;
export const {
  emptyStatus,
  updateDashboard,
  adjustPageAndLimit,
  decreasePageAndlimit,
  emptyAccount,
  setUnchangedTotalSummary,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
